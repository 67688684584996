// AuthModal.js

import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'; // Import react-select

const AuthModal = ({ isOpen, onClose, onAuthSuccess, shares }) => {
  const [authStep, setAuthStep] = useState('selectBrokerage'); // Start with brokerage selection
  const [selectedBrokerage, setSelectedBrokerage] = useState(null); // Store selected brokerage
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [authError, setAuthError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loginLink, setLoginLink] = useState(null);
  const navigate = useNavigate();

  // State to control the "Send Code" button
  const [isSendCodeDisabled, setIsSendCodeDisabled] = useState(false);

  // Brokerage list provided
  const brokerageOptions = [
    { value: 'ALPACA', label: 'Alpaca' },
    { value: 'BINANCE', label: 'Binance' },
    { value: 'CHASE', label: 'Chase' },
    { value: 'COINBASE', label: 'Coinbase' },
    { value: 'ETRADE', label: 'E*TRADE' },
    { value: 'INTERACTIVE-BROKERS-FLEX', label: 'Interactive Brokers' },
    { value: 'KRAKEN', label: 'Kraken' },
    { value: 'QUESTRADE-UNOFFICIAL', label: 'Questrade' },
    { value: 'ROBINHOOD', label: 'Robinhood' },
    { value: 'SCHWAB', label: 'Schwab' },
    { value: 'TRADESTATION', label: 'TradeStation' },
    { value: 'TRADIER', label: 'Tradier' },
    { value: 'VANGUARD', label: 'Vanguard US' },
    { value: 'WEALTHSIMPLETRADE', label: 'Wealthsimple' },
    { value: 'WEBULL', label: 'Webull US' },
  ];

  // Function to handle brokerage selection
  const handleBrokerageSelect = (selectedOption) => {
    setSelectedBrokerage(selectedOption);
    setAuthStep('email'); // Proceed to email verification
  };

  // Function to handle email submission
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setAuthError(null);
    setIsSendCodeDisabled(true); // Disable the button after it's clicked

    try {
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          channel: 'email', // Send an OTP code via email
          shouldCreateUser: true,
        },
      });

      if (error) {
        setAuthError(error.message);
        setIsSendCodeDisabled(false); // Re-enable if there's an error
      } else {
        setAuthStep('otp'); // Move to OTP input step
      }
    } catch (err) {
      setAuthError('An unexpected error occurred. Please try again.');
      console.error(err);
      setIsSendCodeDisabled(false); // Re-enable if there's an exception
    }
  };

  // Function to handle OTP verification
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setAuthError(null);

    try {
      const { data, error } = await supabase.auth.verifyOtp({
        email,
        token: otp,
        type: 'email',
      });

      if (error) {
        setAuthError(error.message);
      } else {
        // Authentication successful
        const user = data.user;
        setUserId(user.id);

        // Store Supabase user ID in localStorage
        localStorage.setItem('supabaseUserId', user.id);

        // Check if user is already linked with SnapTrade
        const isLinked = await checkIfUserIsLinked(user.id);

        if (isLinked) {
          // User is already linked, proceed to post-authorization
          onAuthSuccess(user.id, shares); // Pass shares
          onClose();
          navigate('/post-authorization', { state: { shares } });
        } else {
          // User is not linked, proceed to linking step
          setAuthStep('linking');

          // Fetch SnapTrade login link
          await fetchLoginLink(user.id);
        }
      }
    } catch (err) {
      setAuthError('An unexpected error occurred. Please try again.');
      console.error(err);
    }
  };

  // Function to check if user is already linked
  const checkIfUserIsLinked = async (supabaseUserId) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await fetch(`${backendUrl}/is-linked`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Supabase-User-ID': supabaseUserId,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Is user linked:', data.isLinked);
        return data.isLinked; // Expects { isLinked: true/false }
      } else {
        console.error('Failed to check if user is linked');
        return false;
      }
    } catch (error) {
      console.error('Error during checkIfUserIsLinked:', error);
      return false;
    }
  };

  // Function to fetch SnapTrade login link
  const fetchLoginLink = async (supabaseUserId) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      // Send request to get the login link, passing the Supabase user ID and selected brokerage
      const response = await fetch(`${backendUrl}/link-snaptrade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Supabase-User-ID': supabaseUserId,
        },
        body: JSON.stringify({
          snaptradeUserId: `snaptrade-${supabaseUserId}`,
          brokerage: selectedBrokerage.value,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Received login link:', data.redirectURI);
        setLoginLink(data.redirectURI);
      } else {
        const errorData = await response.json();
        throw new Error(
          errorData.message || 'Failed to get SnapTrade login link'
        );
      }
    } catch (error) {
      console.error('Error during fetching login link:', error);
      setAuthError(error.message);
    }
  };

  // Handle window messages from SnapTrade iframe
  useEffect(() => {
    if (authStep === 'linking') {
      const handleMessageEvent = (e) => {
        if (e.origin !== 'https://app.snaptrade.com') {
          // Ensure we're receiving messages from SnapTrade
          return;
        }

        if (e.data) {
          let data = e.data;

          if (typeof data === 'string') {
            // Check for known string messages
            if (
              data === 'CLOSE_MODAL' ||
              data === 'ABANDONED' ||
              data === 'CLOSED'
            ) {
              console.log('User closed the SnapTrade linking modal');
              setAuthError('SnapTrade linking was cancelled.');
              return;
            } else if (data.startsWith('SUCCESS')) {
              console.log('SnapTrade linking successful');
              // Close the modal and proceed
              onAuthSuccess(userId, shares); // Pass shares
              onClose();
              // Redirect to post-authorization page
              navigate('/post-authorization', { state: { shares } });
              return;
            } else if (data.startsWith('ERROR')) {
              console.error('Error during SnapTrade linking:', data);
              setAuthError(`Error: ${data}`);
              return;
            }
          }
          // If data is not a string or doesn't match known formats, ignore it
        }
      };

      window.addEventListener('message', handleMessageEvent, false);

      return () => {
        window.removeEventListener('message', handleMessageEvent, false);
      };
    }
  }, [authStep, onAuthSuccess, onClose, userId, navigate, shares]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md p-6 bg-white rounded-md">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold">
            {/* Update the labels of the pop-up here */}
            {authStep === 'selectBrokerage' && 'Select Your Brokerage'}
            {authStep === 'email' && 'Verify Email to Continue'}
            {authStep === 'otp' && 'Enter Verification Code'}
            {authStep === 'linking' && 'Sign In to Your Brokerage'}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        {authError && <div className="mb-4 text-red-500">{authError}</div>}
        {authStep === 'selectBrokerage' && (
          <div>
            <p className="mb-4">Please select your brokerage to continue:</p>
            <Select
              options={brokerageOptions}
              onChange={handleBrokerageSelect}
              placeholder="Select your brokerage..."
              isSearchable
            />
          </div>
        )}
        {authStep === 'email' && (
          <form onSubmit={handleEmailSubmit}>
            <label className="block mb-2 font-bold">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isSendCodeDisabled}
              className="w-full p-2 mb-4 border rounded-md"
            />
            <button
              type="submit"
              className={`w-full py-2 text-white rounded-md ${
                isSendCodeDisabled
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-blue-500'
              }`}
              disabled={isSendCodeDisabled}
            >
              {isSendCodeDisabled ? 'Code Sent' : 'Send Code'}
            </button>
          </form>
        )}
        {authStep === 'otp' && (
          <form onSubmit={handleOtpSubmit}>
            <label className="block mb-2 font-bold">Verification Code</label>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              className="w-full p-2 mb-4 border rounded-md"
            />
            <button
              type="submit"
              className="w-full py-2 text-white bg-blue-500 rounded-md"
            >
              Verify Code
            </button>
          </form>
        )}
        {authStep === 'linking' && (
          <div>
            {loginLink ? (
              <iframe
                id="snaptrade-connection-portal"
                src={loginLink}
                title="SnapTrade Connection Portal"
                width="100%"
                height="600px"
                allowFullScreen
              ></iframe>
            ) : (
              <p>Loading trading account linking...</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthModal;
