import React, { useState, useEffect } from 'react';
import { SymbolOverview } from 'react-tradingview-embed';
import axios from 'axios';
import NavBar from '../NavBar';
import AuthModal from '../InfoComponents/AuthModal';
import { useNavigate } from 'react-router-dom';

const CompanyInfo = () => {
  const [shares, setShares] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentNews, setCurrentNews] = useState(null);
  const [stats, setStats] = useState([]);
  const [error, setError] = useState(null);
  const [marketPrice, setMarketPrice] = useState('1.96'); // Default price as a string

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleSharesChange = (e) => {
    setShares(e.target.value);
  };

  const estimatedCost = (shares * parseFloat(marketPrice)).toFixed(2);

  const videos = [
    {
      title: 'Exploration Update',
      url: 'https://www.youtube.com/embed/15z7jAOJqRE',
      thumbnail: 'https://img.youtube.com/vi/15z7jAOJqRE/hqdefault.jpg',
    },
    {
      title: "Silver's Big Moment is Coming",
      url: 'https://www.youtube.com/embed/1BN7CHSlslY',
      thumbnail: 'https://img.youtube.com/vi/1BN7CHSlslY/hqdefault.jpg',
    },
    {
      title:
        'Reawakening of Past Treasures: Our Journey | Dolly Varden Silver',
      url: 'https://www.youtube.com/embed/3oaY-bJ-dRg',
      thumbnail: 'https://img.youtube.com/vi/3oaY-bJ-dRg/hqdefault.jpg',
    },
    {
      title: 'How did we grow 162.79% in 5 years?',
      url: 'https://www.youtube.com/embed/AggmqRoz16U',
      thumbnail: 'https://img.youtube.com/vi/AggmqRoz16U/hqdefault.jpg',
    },
  ];

  // Updated newsItems array with URLs
  const newsItems = [
    {
      title:
        'Dolly Varden Silver Announces Closing of Second Tranche of Bought-Deal Offering for a Total of $32.2 Million',
      pubDate: 'September 27, 2024',
      url: 'https://dollyvardensilver.com/dolly-varden-silver-announces-closing-of-second-tranche-of-bought-deal-offering-for-a-total-of-32-2-million/',
    },
    {
      title:
        'Dolly Varden Silver Intersects 654 g/t Silver over 21.48 meters at Wolf Vein and Expands 2024 Drill Program',
      pubDate: 'September 9, 2024',
      url: 'https://dollyvardensilver.com/dolly-varden-silver-intersects-654-g-t-silver-over-21-48-meters-at-wolf-vein-and-expands-2024-drill-program/',
    },
    {
      title:
        'Dolly Varden Silver Announces Closing of First Tranche of $28 Million Bought-Deal Offering, With Participation by Eric Sprott',
      pubDate: 'September 4, 2024',
      url: 'https://dollyvardensilver.com/dolly-varden-silver-announces-closing-of-first-tranche-of-28-million-bought-deal-offering-with-participation-by-eric-sprott/',
    },
    {
      title:
        'Dolly Varden Silver Announces Upsize of Bought-Deal Financing to $28 Million, With Participation by Eric Sprott',
      pubDate: 'August 20, 2024',
      url: 'https://dollyvardensilver.com/dolly-varden-silver-announces-upsize-of-bought-deal-financing-to-28-million-with-participation-by-eric-sprott/',
    },
  ];

  // Function to open the video modal
  const openVideoModal = (video) => {
    setCurrentVideo(video);
    setShowModal('video');
  };

  // Function to open the news modal
  const openNewsModal = (newsItem) => {
    setCurrentNews(newsItem);
    setShowModal('news');
  };

  // Function to close the modal
  const closeModal = () => {
    setCurrentVideo(null);
    setCurrentNews(null);
    setShowModal(false);
  };

  // Function to handle "Next" button click
  const handleNextClick = () => {
    setIsAuthModalOpen(true);
  };

  // Fetch key statistics using useEffect
  useEffect(() => {
    const fetchStats = async () => {
      const ALPHA_VANTAGE_API_KEY =
        process.env.REACT_APP_ALPHA_VANTAGE_API_KEY;
      const symbol = 'DV.V'; // Dolly Varden Silver on TSXV

      if (!ALPHA_VANTAGE_API_KEY) {
        console.error(
          'API key is missing. Please set REACT_APP_ALPHA_VANTAGE_API_KEY in your .env file.'
        );
        setError('API key is missing.');
        return;
      }

      try {
        // Fetch global quote data from Alpha Vantage
        const response = await axios.get(
          'https://www.alphavantage.co/query',
          {
            params: {
              function: 'GLOBAL_QUOTE',
              symbol: symbol,
              apikey: ALPHA_VANTAGE_API_KEY,
            },
          }
        );

        const data = response.data['Global Quote'];

        if (data && data['05. price']) {
          // Format market price to two decimal places
          setMarketPrice(parseFloat(data['05. price']).toFixed(2));

          // Format and map data to stats array
          const fetchedStats = [
            {
              title: 'Open Price',
              value: data['02. open']
                ? `$${parseFloat(data['02. open']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'High Price',
              value: data['03. high']
                ? `$${parseFloat(data['03. high']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Low Price',
              value: data['04. low']
                ? `$${parseFloat(data['04. low']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Previous Close',
              value: data['08. previous close']
                ? `$${parseFloat(data['08. previous close']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Change',
              value: data['09. change']
                ? parseFloat(data['09. change']).toFixed(2)
                : 'N/A',
            },
            {
              title: 'Percent Change',
              value: data['10. change percent']
                ? `${parseFloat(
                    data['10. change percent'].replace('%', '')
                  ).toFixed(2)}%`
                : 'N/A',
            },
          ];

          setStats(fetchedStats);
        } else {
          console.error('No data available for the provided symbol.');
          setError('No data available for the provided symbol.');
        }
      } catch (error) {
        console.error('Error fetching key statistics:', error);
        setError('Failed to fetch key statistics.');
      }
    };

    fetchStats();
  }, []);

  return (
    <div>
      <NavBar />
      <div className="container p-6 mx-auto">
        {/* Display error message if any */}
        {error && <div className="mb-4 text-red-500">{error}</div>}

        {/* Main Content and Buy Card Grid */}
        <div className="grid grid-cols-1 gap-6 mt-6 md:grid-cols-3">
          {/* Main Content */}
          <div className="md:col-span-2">
            {/* Chart */}
            <div className="my-6">
              <div className="rounded-md h-96">
                <SymbolOverview
                  widgetProps={{
                    colorTheme: 'light',
                    symbols: [['TSXV:DV|1Y']],
                    showVolume: true,
                    backgroundColor: 'rgba(19, 23, 34, 0)',
                    widgetFontColor: 'rgba(19, 23, 43, 1)',
                  }}
                />
              </div>
            </div>

            {/* About DV */}
            <div>
              <h2 className="mb-2 text-lg font-semibold">About DV</h2>
              <p className="mb-4 text-gray-700">
                Dolly Varden Silver Corporation is a mineral exploration company focused on advancing its 100% held Kitsault Valley Project located in the Golden Triangle of British Columbia, Canada, 25kms by road to deep tide water.
                The 163 sq. km. project hosts the high-grade silver and gold resources of Dolly Varden and Homestake Ridge along with the past producing Dolly Varden and Torbrit silver mines. It is considered to be prospective for hosting further precious metal deposits, being on the same structural and stratigraphic belts that host numerous other, on-trend, high-grade deposits, such as Eskay Creek and Brucejack. The project also contains the Big Bulk property which is prospective for porphyry and skarn style copper and gold mineralization, similar to other such deposits in the region (Red Mountain, KSM, Red Chris).
                The Company’s common shares are listed and traded on the TSX.V under the symbol DV and on the OTCQX system under the symbol DOLLF.
              </p>

              {/* DV Key Statistics */}
              <h3 className="mb-2 text-lg font-semibold">
                DV Key Statistics
              </h3>
              <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
                {stats.length > 0 ? (
                  stats.map((stat, index) => (
                    <div key={index} className="text-left">
                      <p className="font-bold">{stat.title}</p>
                      <p className="text-gray-700">{stat.value}</p>
                    </div>
                  ))
                ) : (
                  <p>Loading key statistics...</p>
                )}
              </div>

              {/* Company Presentation */}
              <div className="my-6">
                <h3 className="text-lg font-semibold">
                  Company Presentation
                </h3>
                <div className="mt-4">
                  <iframe
                    src="https://vrify.com/embed/decks/c1254d34-3ce4-45ce-b1c5-9759ff040cdc"
                    title="Vrify Deck"
                    width="100%"
                    height="600px"
                    allowFullScreen
                    className="border rounded-md"
                  ></iframe>
                </div>
              </div>

              {/* DV Media */}
              <div className="my-6">
                <h3 className="text-lg font-semibold">DV Media</h3>
                <div className="flex flex-wrap gap-4 mt-4">
                  {videos.map((video, i) => (
                    <div
                      key={i}
                      className="relative w-full md:w-[48%] h-64 bg-gray-200 rounded-md cursor-pointer overflow-hidden"
                      onClick={() => openVideoModal(video)}
                    >
                      <img
                        src={video.thumbnail}
                        alt={video.title}
                        className="object-cover w-full h-full"
                      />
                      {/* Dark overlay */}
                      <div className="absolute inset-0 bg-black opacity-25"></div>
                      {/* Play Icon */}
                      <div className="absolute inset-0 flex items-center justify-center">
                        <svg
                          className="w-16 h-16 text-white opacity-75"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M6 4l12 6-12 6V4z" />
                        </svg>
                      </div>
                      {/* Video Title */}
                      <div className="absolute bottom-0 w-full p-2 text-sm text-white bg-black bg-opacity-50">
                        {video.title}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* DV News */}
              <div className="my-6">
                <h3 className="text-lg font-semibold">DV News</h3>
                <ul className="mt-4 space-y-4 text-gray-700">
                  {newsItems.length > 0 ? (
                    newsItems.map((newsItem, i) => (
                      <li
                        key={i}
                        className="cursor-pointer"
                        onClick={() => openNewsModal(newsItem)}
                      >
                        <p>{newsItem.pubDate}</p>
                        <p className="font-semibold">{newsItem.title}</p>
                      </li>
                    ))
                  ) : (
                    <p>No news available.</p>
                  )}
                </ul>
              </div>
            </div>
          </div>

          {/* Buy Card */}
          <div className="sticky p-4 border border-blue-500 rounded-md top-20 h-max">
            <h2 className="mb-4 text-lg font-semibold">Buy DV</h2>
            <div className="flex items-center justify-between mb-4">
              <label className="font-bold">Shares</label>
              <input
                type="number"
                value={shares}
                onChange={handleSharesChange}
                placeholder="Shares"
                className="w-24 p-2 border rounded-md"
              />
            </div>
            <div className="flex items-center justify-between mb-2">
              <p className="font-bold">Market Price</p>
              <p>${marketPrice}</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="font-bold">Estimated Cost</p>
              <p>${estimatedCost}</p>
            </div>
            <button
              className="w-full py-2 mt-4 text-white bg-blue-500 rounded-md"
              onClick={handleNextClick}
            >
              Select Brokerage
            </button>
          </div>
        </div>

        {/* Video Modal */}
        {showModal === 'video' && currentVideo && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-5xl p-4 bg-white rounded-md">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold">
                  {currentVideo.title}
                </h2>
                <button
                  onClick={closeModal}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              <div className="relative" style={{ paddingTop: '56.25%' }}>
                <iframe
                  src={`${currentVideo.url}?autoplay=1`}
                  title={currentVideo.title}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full"
                ></iframe>
              </div>
            </div>
          </div>
        )}

        {/* News Modal */}
        {showModal === 'news' && currentNews && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-3xl max-h-full p-4 overflow-y-auto bg-white rounded-md">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold">
                  {currentNews.title}
                </h2>
                <button
                  onClick={closeModal}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              <div>
                <p>{currentNews.pubDate}</p>
                <div className="mt-4">
                  {/* Embed the news page using an iframe */}
                  <iframe
                    src={currentNews.url}
                    title={currentNews.title}
                    width="100%"
                    height="600px"
                    frameBorder="0"
                    className="border rounded-md"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* AuthModal Component */}
        <AuthModal
          isOpen={isAuthModalOpen}
          onClose={() => setIsAuthModalOpen(false)}
          onAuthSuccess={(userId, shares) => {
            // Handle successful authentication and SnapTrade linking
            console.log('Auth success:', userId);
            setIsAuthModalOpen(false);
            // Redirect to post-authorization page with shares value
            navigate('/post-authorization', { state: { shares } });
          }}
          shares={shares} // Pass shares as a prop
        />
      </div>
    </div>
  );
};

export default CompanyInfo;
