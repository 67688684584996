// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import SignUp from './components/SignUp';
import EnterGiveaway from './components/EnterGiveaway';
import PostAuthorization from './components/Pages/PostAuthorization';
import OrderConfirmation from './components/OrderConfirmation';
import CompanyInfo from './components/Pages/CompanyInfo';
import Confirmation from './components/Confirmation';
import posthog from 'posthog-js';


const App = () => {
  useEffect(() => {
    // Initialize PostHog
    posthog.init('phc_4PDuS8yZSZrnkKJ1KZmAvTVdNlpZHPk5CGpOypwDtC6', {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'always',
    });
  }, []);

  return (
    <Router>
      <AppContent /> {/* Render content within Router context */}
    </Router>
  );
};

const AppContent = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page views when location changes
    posthog.capture('$pageview', {
      path: location.pathname,
    });
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<CompanyInfo />} />
      <Route path="/enter-giveaway" element={<EnterGiveaway />} />
      <Route path="/post-authorization" element={<PostAuthorization />} />
      <Route path="/confirmation" element={<Confirmation />} />
    </Routes>
  );
};

export default App;
