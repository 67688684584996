import React, { useState, useEffect } from 'react';
import { SymbolOverview } from 'react-tradingview-embed';
import axios from 'axios';
import NavBar from '../NavBar';
import LoadingOverlay from '../LoadingOverlay'; // Ensure this component is error-free
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const PostAuthorization = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Access location
  const [shares, setShares] = useState(location.state?.shares || ''); // Initialize shares
  const [showModal, setShowModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentNews, setCurrentNews] = useState(null);
  const [stats, setStats] = useState([]);
  const [error, setError] = useState(null);
  const [marketPrice, setMarketPrice] = useState('1.96'); // Default price as a string

  const [accounts, setAccounts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderType: 'Market',
    quantity: 1,
    limitPrice: '',
  });
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userSecret, setUserSecret] = useState(null);
  const [disableInteractions, setDisableInteractions] = useState(false);

  // New state variables for order confirmation
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [orderData, setOrderData] = useState(null);

  


  // Fetch user credentials and accounts
  useEffect(() => {
    const supabaseUserId = localStorage.getItem('supabaseUserId');
    console.log('Supabase User ID retrieved from local storage:', supabaseUserId);

    if (!supabaseUserId) {
      setError('Supabase User ID is missing.');
      setLoading(false);
      return;
    }

    const fetchUserCredentials = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${backendUrl}/post-authorization`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Supabase-User-ID': supabaseUserId,
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to fetch user credentials: ${errorText}`);
        }

        const data = await response.json();
        setUserId(data.userId);
        setUserSecret(data.userSecret);
        console.log('Fetched user credentials:', data);
      } catch (err) {
        console.error('Error fetching user credentials:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserCredentials();
  }, []);

  useEffect(() => {
    if (!userId || !userSecret) return;

    const fetchAccounts = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${backendUrl}/accounts?userId=${userId}&userSecret=${userSecret}`
        );
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to fetch accounts: ${errorText}`);
        }

        const data = await response.json();
        console.log('Accounts fetched from backend:', data);
        setAccounts(
          data.filter((account) => !account.meta?.type.includes('crypto'))
        );
      } catch (err) {
        console.error('Error fetching accounts:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [userId, userSecret]);

  // Fetch key statistics using useEffect
  useEffect(() => {
    const fetchStats = async () => {
      const ALPHA_VANTAGE_API_KEY = process.env.REACT_APP_ALPHA_VANTAGE_API_KEY;
      const symbol = 'DV.V'; // Dolly Varden Silver on TSXV

      if (!ALPHA_VANTAGE_API_KEY) {
        console.error(
          'API key is missing. Please set REACT_APP_ALPHA_VANTAGE_API_KEY in your .env file.'
        );
        setError('API key is missing.');
        return;
      }

      try {
        // Fetch global quote data from Alpha Vantage
        const response = await axios.get('https://www.alphavantage.co/query', {
          params: {
            function: 'GLOBAL_QUOTE',
            symbol: symbol,
            apikey: ALPHA_VANTAGE_API_KEY,
          },
        });

        const data = response.data['Global Quote'];

        if (data && data['05. price']) {
          // Format market price to two decimal places
          setMarketPrice(parseFloat(data['05. price']).toFixed(2));

          // Format and map data to stats array
          const fetchedStats = [
            {
              title: 'Open Price',
              value: data['02. open']
                ? `$${parseFloat(data['02. open']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'High Price',
              value: data['03. high']
                ? `$${parseFloat(data['03. high']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Low Price',
              value: data['04. low']
                ? `$${parseFloat(data['04. low']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Previous Close',
              value: data['08. previous close']
                ? `$${parseFloat(data['08. previous close']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Change',
              value: data['09. change']
                ? parseFloat(data['09. change']).toFixed(2)
                : 'N/A',
            },
            {
              title: 'Percent Change',
              value: data['10. change percent']
                ? `${parseFloat(
                    data['10. change percent'].replace('%', '')
                  ).toFixed(2)}%`
                : 'N/A',
            },
          ];

          setStats(fetchedStats);
        } else {
          console.error('No data available for the provided symbol.');
          setError('No data available for the provided symbol.');
        }
      } catch (error) {
        console.error('Error fetching key statistics:', error);
        setError('Failed to fetch key statistics.');
      }
    };

    fetchStats();
  }, []);

  // Adjust handleSharesChange if needed
  const handleSharesChange = (e) => {
    const value = e.target.value;
    // Ensure shares is a positive integer
    if (value === '' || (/^\d+$/.test(value) && parseInt(value) > 0)) {
      setShares(value);
    }
  };

  const estimatedCost = shares
    ? (shares * parseFloat(marketPrice)).toFixed(2)
    : '0.00';

  const handleBuyClick = () => {
    if (!selectedAccount) {
      alert('Please select an account before proceeding.');
      return;
    }
    // Proceed to place the order
    handleBuyStock();
  };

  const handleSelectAccount = async (accountId) => {
    setDisableInteractions(true); // Disable interactions when an account is clicked
    setIsLoading(true); // Show loading overlay
    setSelectedAccount(accountId);
    try {
      const symbolResponse = await fetch(
        `${backendUrl}/find-symbol?userId=${userId}&userSecret=${userSecret}&ticker=DV.V&exchange=TSXV&accountId=${accountId}`
      );
      if (!symbolResponse.ok) throw new Error('Failed to fetch symbol');
      const symbolData = await symbolResponse.json();
      const universalSymbolId = symbolData.id;

      setCompanyName(symbolData.description);

      const stockResponse = await fetch(
        `${backendUrl}/stock-price?userId=${userId}&userSecret=${userSecret}&accountId=${accountId}&universalSymbolId=${universalSymbolId}`
      );
      if (!stockResponse.ok) throw new Error('Failed to fetch stock price');
      const stockData = await stockResponse.json();
      setMarketPrice(
        stockData[0]?.last_trade_price
          ? parseFloat(stockData[0].last_trade_price).toFixed(2)
          : 'N/A'
      );
      // No need to open a modal; confirmation is handled within the Buy DV box
    } catch (err) {
      console.error('Error fetching symbol or stock price:', err);
      setError(err.message);
    } finally {
      setIsLoading(false); // Hide loading overlay
      setDisableInteractions(false);
    }
  };

  const handleBuyStock = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${backendUrl}/buy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          userSecret,
          accountId: selectedAccount,
          ticker: 'DV.VN',
          quantity: parseInt(shares),
          orderType: 'Market',
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to place order: ${errorText}`);
      }

      const data = await response.json();
      console.log('Order response:', data);

      // Get the selected account's name for the confirmation
      const account = accounts.find((acc) => acc.id === selectedAccount);
      const accountName = account ? account.name : 'Unknown Account';

      // Prepare order details
      const confirmedOrderData = {
        symbol: 'DV.VN',
        companyName: companyName || 'Dolly Varden Silver',
        orderType: 'Market',
        quantity: shares,
        marketPrice: marketPrice,
        estimatedCost: estimatedCost,
        accountName: accountName,
        orderId: data.id || 'N/A', // Adjust based on your backend response
        orderStatus: data.status || 'Pending', // Adjust based on your backend response
      };

      // Update state to show confirmation
      setOrderData(confirmedOrderData);
      setOrderConfirmed(true);
    } catch (err) {
      console.error('Error placing order:', err);
      setError(err.message);
      alert(`Error placing order: ${err.message}`);
    } finally {
      setIsLoading(false);
      setDisableInteractions(false); // Re-enable interactions after action is complete
    }
  };

  const videos = [
    {
      title: 'Exploration Update',
      url: 'https://www.youtube.com/embed/15z7jAOJqRE',
      thumbnail: 'https://img.youtube.com/vi/15z7jAOJqRE/hqdefault.jpg',
    },
    {
      title: "Silver's Big Moment is Coming",
      url: 'https://www.youtube.com/embed/1BN7CHSlslY',
      thumbnail: 'https://img.youtube.com/vi/1BN7CHSlslY/hqdefault.jpg',
    },
    {
      title:
        'Reawakening of Past Treasures: Our Journey | Dolly Varden Silver',
      url: 'https://www.youtube.com/embed/3oaY-bJ-dRg',
      thumbnail: 'https://img.youtube.com/vi/3oaY-bJ-dRg/hqdefault.jpg',
    },
    {
      title: 'How did we grow 162.79% in 5 years?',
      url: 'https://www.youtube.com/embed/AggmqRoz16U',
      thumbnail: 'https://img.youtube.com/vi/AggmqRoz16U/hqdefault.jpg',
    },
  ];

  // Function to open the video modal
  const openVideoModal = (video) => {
    setCurrentVideo(video);
    setShowModal('video');
  };

  // Function to open the news modal
  const openNewsModal = (newsItem) => {
    setCurrentNews(newsItem);
    setShowModal('news');
  };

  // Function to close the modal
  const closeModal = () => {
    setCurrentVideo(null);
    setCurrentNews(null);
    setShowModal(false);
  };

  // Updated newsItems array with URLs
  const newsItems = [
    {
      title:
        'Dolly Varden Silver Announces Closing of Second Tranche of Bought-Deal Offering for a Total of $32.2 Million',
      pubDate: 'September 27, 2024',
      url: 'https://dollyvardensilver.com/dolly-varden-silver-announces-closing-of-second-tranche-of-bought-deal-offering-for-a-total-of-32-2-million/', // Replace with actual URL
    },
    {
      title:
        'Dolly Varden Silver Intersects 654 g/t Silver over 21.48 meters at Wolf Vein and Expands 2024 Drill Program',
      pubDate: 'September 9, 2024',
      url: 'https://dollyvardensilver.com/dolly-varden-silver-intersects-654-g-t-silver-over-21-48-meters-at-wolf-vein-and-expands-2024-drill-program/', // Replace with actual URL
    },
    {
      title:
        'Dolly Varden Silver Announces Closing of First Tranche of $28 Million Bought-Deal Offering, With Participation by Eric Sprott',
      pubDate: 'September 4, 2024',
      url: 'https://dollyvardensilver.com/dolly-varden-silver-announces-closing-of-first-tranche-of-28-million-bought-deal-offering-with-participation-by-eric-sprott/', // Replace with actual URL
    },
    {
      title:
        'Dolly Varden Silver Announces Upsize of Bought-Deal Financing to $28 Million, With Participation by Eric Sprott',
      pubDate: 'August 20, 2024',
      url: 'https://dollyvardensilver.com/dolly-varden-silver-announces-upsize-of-bought-deal-financing-to-28-million-with-participation-by-eric-sprott/', // Replace with actual URL
    },
  ];

  if (loading || accounts === null) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div>
      <NavBar />

      {/* Display the loading overlay when isLoading is true */}
      {isLoading && <LoadingOverlay />}

      <div className="container p-6 mx-auto">
        {/* Display error message if any */}
        {error && (
          <div className="p-4 mb-4 text-red-700 bg-red-100 rounded-md">
            {error}
          </div>
        )}

        {/* Main Content and Buy Card Grid */}
        <div className="grid grid-cols-1 gap-6 mt-6 md:grid-cols-3">
          {/* Main Content */}
          <div className="md:col-span-2">
            {/* Chart */}
            <div className="my-6">
              <div className="rounded-md h-96">
                <SymbolOverview
                  widgetProps={{
                    colorTheme: 'light',
                    symbols: [['TSXV:DV|1Y']],
                    showVolume: true,
                    backgroundColor: 'rgba(19, 23, 34, 0)',
                    widgetFontColor: 'rgba(19, 23, 43, 1)',
                  }}
                />
              </div>
            </div>

            {/* About DV */}
            <div>
              <h2 className="mb-2 text-lg font-semibold">About DV</h2>
              <p className="mb-4 text-gray-700">
              Dolly Varden Silver Corporation is a mineral exploration company focused on advancing its 100% held Kitsault Valley Project located in the Golden Triangle of British Columbia, Canada, 25kms by road to deep tide water.
              </p>

              {/* DV Key Statistics */}
              <h3 className="mb-2 text-lg font-semibold">DV Key Statistics</h3>
              <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
                {stats.length > 0 ? (
                  stats.map((stat, index) => (
                    <div key={index} className="text-left">
                      <p className="font-bold">{stat.title}</p>
                      <p className="text-gray-700">{stat.value}</p>
                    </div>
                  ))
                ) : (
                  <p>Loading key statistics...</p>
                )}
              </div>

              {/* Company Presentation */}
              <div className="my-6">
                <h3 className="text-lg font-semibold">Company Presentation</h3>
                <div className="mt-4">
                  <iframe
                    src="https://vrify.com/embed/decks/c1254d34-3ce4-45ce-b1c5-9759ff040cdc"
                    title="Vrify Deck"
                    width="100%"
                    height="600px"
                    allowFullScreen
                    className="border rounded-md"
                  ></iframe>
                </div>
              </div>

              {/* DV Media */}
              <div className="my-6">
                <h3 className="text-lg font-semibold">DV Media</h3>
                <div className="flex flex-wrap gap-4 mt-4">
                  {videos.map((video, i) => (
                    <div
                      key={i}
                      className="relative w-full md:w-[48%] h-64 bg-gray-200 rounded-md cursor-pointer overflow-hidden"
                      onClick={() => openVideoModal(video)}
                    >
                      <img
                        src={video.thumbnail}
                        alt={video.title}
                        className="object-cover w-full h-full"
                      />
                      {/* Dark overlay */}
                      <div className="absolute inset-0 bg-black opacity-25"></div>
                      {/* Play Icon */}
                      <div className="absolute inset-0 flex items-center justify-center">
                        <svg
                          className="w-16 h-16 text-white opacity-75"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M6 4l12 6-12 6V4z" />
                        </svg>
                      </div>
                      {/* Video Title */}
                      <div className="absolute bottom-0 w-full p-2 text-sm text-white bg-black bg-opacity-50">
                        {video.title}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* DV News */}
              <div className="my-6">
                <h3 className="text-lg font-semibold">DV News</h3>
                <ul className="mt-4 space-y-4 text-gray-700">
                  {newsItems.length > 0 ? (
                    newsItems.map((newsItem, i) => (
                      <li
                        key={i}
                        className="cursor-pointer"
                        onClick={() => openNewsModal(newsItem)}
                      >
                        <p>{newsItem.pubDate}</p>
                        <p className="font-semibold">{newsItem.title}</p>
                      </li>
                    ))
                  ) : (
                    <p>No news available.</p>
                  )}
                </ul>
              </div>
            </div>
          </div>

          {/* Buy Card */}
          <div className="sticky self-start p-4 border border-blue-500 rounded-md md:col-span-1 top-20">
          {!orderConfirmed ? (
            <>
              <h2 className="mb-4 text-lg font-semibold">Buy DV</h2>
              <div className="flex items-center justify-between mb-4">
                <label className="font-bold">Shares</label>
                <input
                  type="number"
                  value={shares}
                  onChange={handleSharesChange}
                  placeholder="Shares"
                  className="w-24 p-2 border rounded-md"
                  min="1"
                />
              </div>
                <div className="flex items-center justify-between mb-2">
                  <p className="font-bold">Market Price</p>
                  <p>${marketPrice}</p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="font-bold">Estimated Cost</p>
                  <p>${estimatedCost}</p>
                </div>

                {/* Account Selection */}
                <div className="mt-4">
                  <label className="font-bold">Select Account</label>
                  <select
                    value={selectedAccount || ''}
                    onChange={(e) => handleSelectAccount(e.target.value)}
                    className="w-full p-2 mt-2 border rounded-md"
                  >
                    <option value="" disabled>
                      -- Select an Account --
                    </option>
                    {accounts.map((account) => (
                      <option key={account.id} value={account.id}>
                        {account.name.replace('Wealthsimple Trade', '')} - $
                        {account.balance !== null && account.balance !== undefined
                          ? account.balance.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 'N/A'}
                      </option>
                    ))}
                  </select>
                </div>

                <button
                  className={`w-full py-2 mt-4 text-white bg-blue-500 rounded-md ${
                    !selectedAccount || !shares
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                  onClick={handleBuyClick}
                  disabled={!selectedAccount || !shares}
                >
                  Buy
                </button>
              </>
            ) : (
              <div className="p-6 bg-white rounded-lg shadow-lg">
                <div className="flex items-center justify-center mb-4">
                  {/* Success Icon */}
                  <svg
                    className="w-12 h-12 text-green-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <h3 className="mb-2 text-xl font-semibold text-center">
                  Order Confirmed
                </h3>
                <p className="mb-6 text-center text-gray-600">
                  Your order has been placed successfully.
                </p>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Account:</span>
                    <span className="text-gray-900">
                      {orderData?.accountName || 'N/A'}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Symbol:</span>
                    <span className="text-gray-900">
                      {orderData?.symbol || 'N/A'} ({orderData?.companyName || 'N/A'})
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Order Type:</span>
                    <span className="text-gray-900">
                      {orderData?.orderType || 'N/A'}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Quantity:</span>
                    <span className="text-gray-900">
                      {orderData?.quantity || 'N/A'}
                    </span>
                  </div>
                  {orderData?.orderType === 'Limit' && (
                    <div className="flex justify-between">
                      <span className="font-medium text-gray-700">Limit Price:</span>
                      <span className="text-gray-900">
                        ${orderData.limitPrice || 'N/A'}
                      </span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Market Price:</span>
                    <span className="text-gray-900">
                      ${orderData?.marketPrice || 'N/A'}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Estimated Cost:</span>
                    <span className="text-gray-900">
                      ${orderData?.estimatedCost || 'N/A'}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Order ID:</span>
                    <span className="text-gray-900">
                      {orderData?.orderId || 'N/A'}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Status:</span>
                    <span className="text-gray-900">
                      {orderData?.orderStatus || 'N/A'}
                    </span>
                  </div>
                </div>
                <button
                  onClick={() => {
                    // Reset the form for a new order
                    setOrderConfirmed(false);
                    setOrderData(null);
                    setShares('');
                    setSelectedAccount(null);
                    setCompanyName('');
                    setMarketPrice('1.96'); // Reset to default or fetch latest
                  }}
                  className="w-full py-2 mt-6 text-white transition duration-200 bg-blue-500 rounded-md hover:bg-blue-600"
                >
                  Place Another Order
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Video Modal */}
        {showModal === 'video' && currentVideo && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-5xl p-4 bg-white rounded-md">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold">{currentVideo.title}</h2>
                <button
                  onClick={closeModal}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              <div className="relative" style={{ paddingTop: '56.25%' }}>
                <iframe
                  src={`${currentVideo.url}?autoplay=1`}
                  title={currentVideo.title}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full"
                ></iframe>
              </div>
            </div>
          </div>
        )}

        {/* News Modal */}
        {showModal === 'news' && currentNews && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-3xl max-h-full p-4 overflow-y-auto bg-white rounded-md">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold">{currentNews.title}</h2>
                <button
                  onClick={closeModal}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              <div>
                <p>{currentNews.pubDate}</p>
                <div className="mt-4">
                  <iframe
                    src={currentNews.url}
                    title={currentNews.title}
                    width="100%"
                    height="600px"
                    className="border rounded-md"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostAuthorization;
